const RESTAURANT_BASE_URL = `${process.env.NEXT_PUBLIC_APP_URL}/restaurant`;

const generateRestaurantUrl = (restaurantSlug) => {
  if (!restaurantSlug) return null;

  return `${RESTAURANT_BASE_URL}/${restaurantSlug}`;
};

export {
  generateRestaurantUrl,
  RESTAURANT_BASE_URL
};
