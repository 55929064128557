import React from 'react';
import PropTypes from 'prop-types';

// Utils
import bemify from 'utils/bemify';

// Components
import Sidebar from 'components/sidebar';

// Styles
import stylesModule from './styles.module.scss';


const styles = bemify(stylesModule);

const PageWithSidebar = ({ className, children }) => (
  <div className={`${styles.pageWithSidebar}${className ? ` ${className}` : ''}`}>
    <div className={styles.pageWithSidebar__children}>
      {children}
    </div>
    <Sidebar />
  </div>
);

PageWithSidebar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  className: PropTypes.string
};

PageWithSidebar.defaultProps = {
  className: null
};

export default PageWithSidebar;
