import React from 'react';
import PropTypes from 'prop-types';

// Utils
import bemify from 'utils/bemify';

// Components
import Button from 'components/button';
import Icon from 'components/icon';

// Styles
import stylesModule from './styles.module.scss';


const styles = bemify(stylesModule);

const ButtonsCard = ({
  className,
  items
}) => (
  <div className={`${styles.buttonsCard}${className ? ` ${className}` : ''}`}>
    {Array.isArray(items) && !!items.length && items.map((item, index) => {
      let rightSlot = null;

      if (item.extraIcon) {
        rightSlot = (
          <div className={styles.buttonsCard__rightIconWrapper}>
            <Icon
              className={styles.buttonsCard__buttonIcon_right}
              name={item.extraIcon}
            />
          </div>
        );
      }

      return (
        <Button
          key={index}
          className={styles.buttonsCard__button}
          contentAlignment="left"
          href={item.href}
          icon={item.icon}
          iconPosition="left"
          iconSize={item.iconSize}
          rightSlot={rightSlot}
          target={item.target || null}
          text={item.label}
          textWeight="regular"
          theme="unstyled"
          onClick={() => item.onClick()}
        />
      );
    })}
  </div>
);

ButtonsCard.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    icon: PropTypes.string,
    iconSize: PropTypes.string,
    label: PropTypes.string,
    notificationNumber: PropTypes.number,
    target: PropTypes.string
  })).isRequired
};

ButtonsCard.defaultProps = {
  className: null
};

export default ButtonsCard;
