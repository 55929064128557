import React from 'react';
import PropTypes from 'prop-types';

// Utils
import bemify from 'utils/bemify';

// Components
import SidebarMenuItem from 'components/sidebar/menu-item';

// Styles
import stylesModule from './styles.module.scss';


const styles = bemify(stylesModule);

const SidebarMenuGroup = ({ className, items, label }) => (
  <div className={`${styles.sidebarMenuGroup}${className ? ` ${className}` : ''}`}>
    {!!label && (
      <div className={styles.sidebarMenuGroup__label}>
        {label}
      </div>
    )}
    {Array.isArray(items) && !!items.length && (
      <div className={`${styles.sidebarMenuGroup__itemsWrapper}${items.length === 2 ? ` ${styles.sidebarMenuGroup__itemsWrapper_short}` : ''}`}>
        {items.map((item, index) => (
          <SidebarMenuItem
            key={index}
            clickHandler={item.onClick}
            href={item.href}
            icon={item?.icon}
            iconSize={item?.iconSize}
            label={item?.label}
            notificationNumber={item?.notificationNumber}
            target={item?.target}
          />
        ))}
      </div>
    )}
  </div>
);

SidebarMenuGroup.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    icon: PropTypes.string,
    iconSize: PropTypes.string,
    label: PropTypes.string,
    notificationNumber: PropTypes.number,
    target: PropTypes.string
  })).isRequired,
  label: PropTypes.string
};

SidebarMenuGroup.defaultProps = {
  className: null,
  label: null
};

export default SidebarMenuGroup;
