import React from 'react';
import PropTypes from 'prop-types';
import useTranslationWithFallback from 'hooks/use-translation-with-fallback';

// Utils
import bemify from 'utils/bemify';

// Components
import Image from 'components/image';

// Styles
import stylesModule from './styles.module.scss';
import SidebarMenuItem from '../menu-item';


const styles = bemify(stylesModule);

const SidebarHeader = ({
  className,
  description,
  logo,
  name,
  publicPageLink,
  qrCardDownloader
}) => {
  const { t } = useTranslationWithFallback('common');

  return (
    <section>
      <div className={`${styles.sidebarHeader}${className ? ` ${className}` : ''}`}>
        {!!logo && (
          <div className={styles.sidebarHeader__logo}>
            <Image
              alt={t('restaurant_images_alt.logo')}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              src={logo}
            />
          </div>
        )}
        <div>
          <div
            className={styles.sidebarHeader__name}
            title={name}
          >
            {name}
          </div>
          <div
            className={styles.sidebarHeader__description}
            title={description}
          >
            {description}
          </div>
        </div>
      </div>
      {publicPageLink && (
        <div className={`${styles.sidebarHeader__buttonWrapper} d-none d-md-block`}>
          <SidebarMenuItem
            href={publicPageLink.href}
            icon={publicPageLink.icon}
            iconSize={publicPageLink.iconSize}
            label={publicPageLink.label}
            rightIcon={publicPageLink.extraIcon}
            target={publicPageLink.target}
          />
        </div>
      )}
      {qrCardDownloader && (
        <div className={`${styles.sidebarHeader__buttonWrapper} d-none d-md-block`}>
          <SidebarMenuItem
            clickHandler={qrCardDownloader.onClick}
            disabled={qrCardDownloader.disabled}
            icon={qrCardDownloader.icon}
            iconSize={qrCardDownloader.iconSize}
            label={qrCardDownloader.label}
            rightIcon={qrCardDownloader.extraIcon}
            target={qrCardDownloader.target}
          />
        </div>
      )}
    </section>
  );
};

SidebarHeader.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  logo: PropTypes.string,
  name: PropTypes.string,
  publicPageLink: PropTypes.shape({
    extraIcon: PropTypes.string,
    href: PropTypes.string,
    icon: PropTypes.string,
    iconSize: PropTypes.string,
    label: PropTypes.string,
    target: PropTypes.string
  }),
  qrCardDownloader: PropTypes.shape({
    disabled: PropTypes.bool,
    extraIcon: PropTypes.string,
    href: PropTypes.string,
    icon: PropTypes.string,
    iconSize: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    target: PropTypes.string
  })
};

SidebarHeader.defaultProps = {
  className: null,
  description: null,
  logo: null,
  name: null,
  publicPageLink: null,
  qrCardDownloader: null
};

export default SidebarHeader;
