import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as RadixDialog from '@radix-ui/react-dialog';

// Utils
import bemify from 'utils/bemify';

// Components
import Icon from 'components/icon';

// Styles
import stylesModule from './styles.module.scss';


const styles = bemify(stylesModule);

const Modal = ({
  children,
  className,
  customHeader,
  description,
  descriptionTextColor,
  footer,
  footerClassName,
  footerLeftSlot,
  headerClassName,
  headerIcon,
  onClose,
  mobilePosition,
  noCloseIcon,
  show,
  size,
  title,
  trigger,
  triggerClassName
}) => {
  useEffect(() => {
    if (show) document.documentElement.style.overflow = 'auto';
    else document.documentElement.style.overflow = 'initial';
  }, [show]);

  if (!customHeader && !description && !title && !children) return null;

  const handleModalOpenChange = (value) => {
    if (value === false) onClose();
  };

  const contentWrapperClassNames = `${styles.modal__contentWrapper}${
    className ? ` ${className}` : ''}${
    mobilePosition === 'bottom' ? ` ${styles.modal__contentWrapper_bottom}` : ''}${
    mobilePosition === 'top' ? ` ${styles.modal__contentWrapper_top}` : ''}${
    size === 'l' ? ` ${styles.modal__contentWrapper_large}` : ''}${
    size === 's' ? ` ${styles.modal__contentWrapper_small}` : ''}${
    size === 'xl' ? ` ${styles.modal__contentWrapper_extraLarge}` : ''
  }`;

  return (
    <RadixDialog.Root
      open={show}
      onOpenChange={handleModalOpenChange}
    >
      <RadixDialog.Trigger
        asChild
        className={triggerClassName || null}
      >
        {trigger}
      </RadixDialog.Trigger>
      <RadixDialog.Portal>
        <RadixDialog.Overlay className={styles.modal__overlay} />
        <RadixDialog.Content className={contentWrapperClassNames}>
          {(!!customHeader || !!title || description || !!headerIcon || !noCloseIcon) && (
            <header className={`${styles.modal__contentHeader}${
              headerClassName ? ` ${headerClassName}` : ''}`}
            >
              {!!headerIcon && (
                <div className={styles.modal__contentHeaderIconWrapper}>
                  <Icon
                    className={styles.modal__contentHeaderIcon}
                    name={headerIcon}
                  />
                </div>
              )}
              {customHeader}
              {!customHeader && (!!title || !!description) && (
                <div className={!noCloseIcon ? styles.modal__contentHeader_hasCloseIcon : null}>
                  {!!title && (
                    <div className={styles.modal__title}>
                      {title}
                    </div>
                  )}
                  {!!description && (
                    <div className={`${
                      styles.modal__description}${
                      descriptionTextColor === 'dark-blue' ? ` ${styles.modal__description_darkBlue}` : ''}`}
                    >
                      {description}
                    </div>
                  )}
                </div>
              )}
              {!noCloseIcon && (
                <RadixDialog.Close className={styles.modal__closeButton}>
                  <Icon name="close" />
                </RadixDialog.Close>
              )}
            </header>
          )}
          {!!children && (
            <div className={styles.modal__contentChildren}>
              {children}
            </div>
          )}
          {footer && (
            <div className={`${styles.modal__contentFooter}${
              footerLeftSlot ? ` ${styles.modal__contentFooter_hasLeftSlot}` : ''}${
              footerClassName ? ` ${footerClassName}` : ''}`}
            >
              {!!footerLeftSlot && footerLeftSlot}
              <div>
                {footer}
              </div>
            </div>
          )}
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  className: PropTypes.string,
  customHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  description: PropTypes.string,
  descriptionTextColor: PropTypes.oneOf(['dark-blue', 'gray']),
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  footerClassName: PropTypes.string,
  footerLeftSlot: PropTypes.element,
  headerClassName: PropTypes.string,
  headerIcon: PropTypes.string,
  mobilePosition: PropTypes.oneOf(['top', 'center', 'bottom']),
  noCloseIcon: PropTypes.bool,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  size: PropTypes.oneOf(['default', 's', 'l', 'xl']),
  title: PropTypes.string,
  trigger: PropTypes.element,
  triggerClassName: PropTypes.string
};

Modal.defaultProps = {
  children: null,
  className: null,
  customHeader: null,
  description: null,
  descriptionTextColor: 'gray',
  footer: null,
  footerClassName: null,
  footerLeftSlot: null,
  headerClassName: null,
  headerIcon: null,
  mobilePosition: 'center',
  noCloseIcon: false,
  onClose: () => {},
  show: false,
  size: 'default',
  title: null,
  trigger: null,
  triggerClassName: null
};

export default Modal;
