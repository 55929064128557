import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/legacy/image';
import { QRCodeSVG } from 'qrcode.react';

// Utils
import bemify from 'utils/bemify';
import { generateRestaurantUrl } from 'utils/restaurant-url-helpers';

// Styles
import stylesModule from './styles.module.scss';


const styles = bemify(stylesModule);

const QrCard = ({
  backgroundColor,
  cardRef,
  className,
  description,
  displaySignature,
  logoSrc,
  restaurantSlug,
  size,
  textColor,
  title
}) => {
  // Computed
  const cardClassNames = useMemo(
    () => `${
      styles.qrCard}${
      size === 's' ? ` ${styles.qrCard_small}` : ''}${
      size === 'print' ? ` ${styles.qrCard_print}` : ''}${
      className ? ` ${className}` : ''}`,
    [className, size]
  );

  const codeSize = useMemo(() => {
    // These are the values that will be applied as height and width for the QR code itself
    // In its smaller version, it is 60x60
    if (size === 's') return 60;

    // In its print version, it is 360x360 (3 times the regular size)
    if (size === 'print') return 360;

    // In its regular version, it is 120x120
    return 120;
  }, [size]);

  const pageSrc = useMemo(() => generateRestaurantUrl(restaurantSlug), [restaurantSlug]);

  return (
    <div
      ref={cardRef}
      className={cardClassNames}
    >
      <div
        className={styles.qrCard__content}
        style={{
          backgroundColor
        }}
      >
        <div className={styles.qrCard__contentCode}>
          <QRCodeSVG
            size={codeSize}
            value={pageSrc}
          />
          {/* To be able to convert this card to image, we need to position the logo manually */}
          {!!logoSrc && (
            <div className={styles.qrCard__contentCodeLogo}>
              <Image
                alt="qr-logo"
                layout="fill"
                objectFit="fill"
                src={logoSrc}
              />
            </div>
          )}
        </div>
        {(!!title || !!description) && (
          <div
            className={styles.qrCard__contentText}
            style={{
              color: textColor
            }}
          >
            {!!title && (
              <p className={styles.qrCard__contentTitle}>
                {title}
              </p>
            )}
            {!!description && (
              <p className={styles.qrCard__contentDescription}>
                {description}
              </p>
            )}
          </div>
        )}
      </div>
      {displaySignature && (
        <footer className={styles.qrCard__signature}>
          <div>
            <div className={styles.qrCard__signatureLogo}>
              <Image
                alt="logo"
                layout="fill"
                loading="eager"
                objectFit="contain"
                src="/logos/resty.svg"
              />
            </div>
          </div>
          <span>
            resty.digital
          </span>
        </footer>
      )}
    </div>
  );
};

QrCard.propTypes = {
  backgroundColor: PropTypes.string,
  cardRef: PropTypes.shape({}),
  className: PropTypes.string,
  description: PropTypes.string,
  displaySignature: PropTypes.bool,
  logoSrc: PropTypes.string,
  restaurantSlug: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['s', 'm', 'print']),
  textColor: PropTypes.string,
  title: PropTypes.string
};

QrCard.defaultProps = {
  backgroundColor: '#1B2230',
  cardRef: null,
  className: null,
  description: null,
  displaySignature: true,
  logoSrc: null,
  size: 'm',
  textColor: '#F6F8FC',
  title: null
};

export default QrCard;
