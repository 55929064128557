import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useTranslationWithFallback from 'hooks/use-translation-with-fallback';

// Utils
import bemify from 'utils/bemify';

// Store
import { useDispatch, useSelector } from 'react-redux';
import { fetchRestaurants, selectCurrentRestaurant } from 'store/slices/restaurantsSlice';
import { logoutUser } from 'store/slices/userSlice';

// Context
import { useToastContext } from 'context/toast-context';

// Components
import ButtonsCard from 'components/buttons-card';
import QrCardDownloader from 'components/qr-card-downloader';
import SidebarHeader from 'components/sidebar/header';
import SidebarMenuGroup from 'components/sidebar/menu-group';

// Styles
import stylesModule from './styles.module.scss';


const styles = bemify(stylesModule);


const Sidebar = ({ className }) => {
  const { t } = useTranslationWithFallback('sidebar');

  const dispatch = useDispatch();

  const { showToast } = useToastContext();

  const [isDownloadingCard, setIsDownloadingCard] = useState(false);
  const [isMobileProfilePagesMenuOpen, setIsMobileProfilePagesMenuOpen] = useState(false);

  // Selectors
  const currentRestaurant = useSelector(selectCurrentRestaurant);

  const qrCardPreferences = useMemo(() => {
    if (currentRestaurant) {
      const stringifiedStoredPreferences = localStorage.getItem('qrCardPreferences');

      if (stringifiedStoredPreferences) {
        const storedPreferences = JSON.parse(stringifiedStoredPreferences);

        if (storedPreferences[currentRestaurant.id]) return storedPreferences[currentRestaurant.id];
      }
    }

    return {};
  }, [currentRestaurant]);

  const handleLogoutClick = useCallback(() => {
    dispatch(logoutUser());
  }, [dispatch]);

  const handleProfilePagesMenuClick = () => {
    setIsMobileProfilePagesMenuOpen(previousValue => !previousValue);
  };

  const handleQrCardDownload = () => {
    setIsDownloadingCard(true);
  };

  const handleQrCardDownloadError = () => {
    showToast(t('actions.download_qr_card.error'), 'error');

    setIsDownloadingCard(false);
  };

  const handleQrCardDownloadSuccess = () => {
    showToast(t('actions.download_qr_card.success'), 'success');

    setIsDownloadingCard(false);
  };

  const menuGroups = useMemo(() => {
    const groups = [
      {
        items: [
          {
            href: '/',
            icon: 'home-white',
            iconSize: 'l',
            label: t('menu.restaurant.items.home.label')
          }
        ],
        label: t('menu.restaurant.label')
      },
      {
        items: [
          {
            href: '/restaurant-profile',
            icon: 'user-circle-2',
            iconSize: 'l',
            label: t('menu.settings.items.restaurant_profile.label')
          },
          {
            href: '/account-settings',
            icon: 'user-circle',
            iconSize: 'l',
            label: t('menu.settings.items.individual_profile.label')
          },
          {
            href: '/brand-materials',
            icon: 'copy',
            iconSize: 'l',
            label: t('menu.settings.items.brand_materials.label')
          }
        ],
        label: t('menu.settings.label')
      },
      {
        items: [
          {
            icon: 'sign-out-white',
            iconSize: 's',
            label: t('menu.general.items.logout.label'),
            onClick: handleLogoutClick
          }
        ],
        position: 'bottom'
      }
    ];

    if (currentRestaurant) {
      groups[0].items.push(
        {
          href: '/edit-menu',
          icon: 'newspaper-white',
          iconSize: 'l',
          label: t('menu.restaurant.items.menu.label')
        },
        {
          href: '/products',
          icon: 'products-white',
          iconSize: 'l',
          label: t('menu.restaurant.items.products.label')
        }
      );
    }

    return groups;
  }, [currentRestaurant, handleLogoutClick, t]);


  const menuItemsMobile = useMemo(() => {
    const groups = [
      {
        href: '/',
        icon: 'home-white',
        iconSize: 'l',
        label: t('menu.restaurant.items.home.label')
      }
    ];

    if (currentRestaurant) {
      groups.push(
        {
          href: '/edit-menu',
          icon: 'newspaper-white',
          iconSize: 'l',
          label: t('menu.restaurant.items.menu.label')
        },
        {
          href: '/products',
          icon: 'products-white',
          iconSize: 'l',
          label: t('menu.restaurant.items.products.label')
        }
      );
    }

    groups.push({
      icon: 'user-circle',
      iconSize: 'l',
      label: t('menu.restaurant.items.account.label'),
      onClick: handleProfilePagesMenuClick
    });

    return groups;
  }, [currentRestaurant, t]);

  const publicPageLink = useMemo(() => {
    if (currentRestaurant?.slug) {
      return {
        extraIcon: 'external-link',
        href: `/restaurant/${currentRestaurant.slug}`,
        icon: 'play-filled',
        iconSize: 'l',
        label: t('menu.restaurant.items.public_page.label'),
        target: '_blank'
      };
    }

    return null;
  }, [currentRestaurant, t]);

  const qrCardDownloader = useMemo(() => {
    if (currentRestaurant) {
      return {
        disabled: isDownloadingCard,
        extraIcon: 'download',
        icon: 'qr-code',
        iconSize: 'l',
        label: t('menu.restaurant.items.menu_qr_download.label'),
        onClick: handleQrCardDownload
      };
    }

    return null;
  }, [currentRestaurant, isDownloadingCard, t]);

  const MobileProfilePagesMenuItems = useMemo(() => {
    const items = [
      {
        href: '/account-settings',
        icon: 'user-circle',
        iconSize: 'l',
        label: t('menu.settings.items.individual_profile.label')
      },
      {
        href: '/restaurant-profile',
        icon: 'user-circle-2',
        iconSize: 'l',
        label: t('menu.settings.items.restaurant_profile.label')
      },
      {
        href: '/brand-materials',
        icon: 'copy',
        iconSize: 'l',
        label: t('menu.settings.items.brand_materials.label')
      }
    ];

    if (currentRestaurant?.slug) {
      items.push({
        extraIcon: 'external-link',
        href: `/restaurant/${currentRestaurant.slug}`,
        icon: 'play-filled',
        iconSize: 'l',
        label: t('menu.restaurant.items.public_page.label'),
        target: '_blank'
      });

      items.push({
        disabled: isDownloadingCard,
        extraIcon: 'download',
        icon: 'qr-code',
        iconSize: 'l',
        label: t('menu.restaurant.items.menu_qr_download.label'),
        onClick: handleQrCardDownload
      });
    }

    items.push({
      icon: 'sign-out',
      iconSize: 'l',
      label: t('menu.general.items.logout.label'),
      onClick: handleLogoutClick
    });

    return items;
  }, [t, currentRestaurant, handleLogoutClick, isDownloadingCard]);

  useEffect(() => {
    dispatch(fetchRestaurants());
  }, [dispatch]);

  return (
    <>
      <section className={`${styles.sidebar}${currentRestaurant?.name ? '' : ` ${styles.sidebar_noHeader}`}${className ? ` ${className}` : ''}`}>
        {!!currentRestaurant?.name && (
          <>
            <SidebarHeader
              className={`${styles.sidebar__header} d-none d-md-flex`}
              description={currentRestaurant.category || null}
              logo={currentRestaurant.logoUrl}
              name={currentRestaurant.name}
              publicPageLink={publicPageLink}
              qrCardDownloader={qrCardDownloader}
            />
            <hr className={`${styles.sidebar__divider}${publicPageLink ? ` ${styles.sidebar__divider_shortMargin}` : ''} d-none d-md-flex`} />
          </>
        )}
        {Array.isArray(menuGroups) && !!menuGroups.length && (
          <div className={`${styles.sidebar__content} d-none d-md-grid`}>
            {menuGroups.map((item, index) => (
              <SidebarMenuGroup
                key={index}
                className={`${styles.sidebar__menuGroup}${item.position === 'bottom' ? ` ${styles.sidebar__menuGroup_bottom}` : ''}`}
                items={item.items}
                label={item.label}
              />
            ))}
          </div>
        )}
        {Array.isArray(menuItemsMobile) && !!menuItemsMobile.length && (
          <div className="d-md-none">
            <SidebarMenuGroup
              className={styles.sidebar__menuGroup}
              items={menuItemsMobile}
            />
            {isMobileProfilePagesMenuOpen && Array.isArray(MobileProfilePagesMenuItems) && !!MobileProfilePagesMenuItems.length && (
              <ButtonsCard
                className={styles.sidebar__mobileProfileMenu}
                items={MobileProfilePagesMenuItems}
              />
            )}
          </div>
        )}
      </section>
      {isDownloadingCard && !!currentRestaurant && (
        <QrCardDownloader
          backgroundColor={qrCardPreferences.backgroundColor}
          description={qrCardPreferences.showDescription ? currentRestaurant.category : null}
          logoSrc={qrCardPreferences.showLogo ? currentRestaurant.logoUrl : null}
          restaurantName={currentRestaurant.name}
          restaurantSlug={currentRestaurant.slug}
          textColor={qrCardPreferences.textColor}
          title={qrCardPreferences.showTitle ? currentRestaurant.name : null}
          onError={handleQrCardDownloadError}
          onSuccess={handleQrCardDownloadSuccess}
        />
      )}
    </>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string
};

Sidebar.defaultProps = {
  className: null
};

export default Sidebar;
