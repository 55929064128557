import React from 'react';
import PropTypes from 'prop-types';

// Utils
import bemify from 'utils/bemify';

// Components
import Button from 'components/button';
import Icon from 'components/icon';

// Styles
import stylesModule from './styles.module.scss';


const styles = bemify(stylesModule);

const SidebarMenuItem = ({
  className,
  clickHandler,
  disabled,
  href,
  icon,
  iconSize,
  label,
  notificationNumber,
  rightIcon,
  target
}) => {
  if (!href && !clickHandler) return null;

  let buttonRightSlot = null;

  if (notificationNumber) {
    buttonRightSlot = (
      <div className={styles.sidebarMenuItem__notificationNumberWrapper}>
        <div className={styles.sidebarMenuItem__notificationNumber}>
          {notificationNumber}
        </div>
      </div>
    );
  } else if (rightIcon) {
    buttonRightSlot = (
      <div className={styles.sidebarMenuItem__rightIconWrapper}>
        <Icon
          className={styles.sidebarMenuItem__buttonIcon_right}
          name={rightIcon}
        />
      </div>
    );
  }

  return (
    <div className={`${styles.sidebarMenuItem}${className ? ` ${className}` : ''}`}>
      <Button
        className={styles.sidebarMenuItem__button}
        contentAlignment="left"
        contentClassName={styles.sidebarMenuItem__buttonContent}
        disabled={disabled}
        href={href}
        icon={icon}
        iconClassName={styles.sidebarMenuItem__buttonIcon}
        iconPosition="left"
        iconSize={iconSize}
        rightSlot={buttonRightSlot}
        target={target || null}
        text={label}
        textWeight="regular"
        theme="unstyled"
        onClick={clickHandler}
      />
    </div>
  );
};

SidebarMenuItem.propTypes = {
  className: PropTypes.string,
  clickHandler: PropTypes.func,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.string,
  iconSize: PropTypes.string,
  label: PropTypes.string.isRequired,
  notificationNumber: PropTypes.number,
  rightIcon: PropTypes.string,
  target: PropTypes.string
};

SidebarMenuItem.defaultProps = {
  className: null,
  clickHandler: null,
  disabled: false,
  href: null,
  icon: null,
  iconSize: null,
  notificationNumber: null,
  rightIcon: null,
  target: null
};

export default SidebarMenuItem;
