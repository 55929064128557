import React, { useCallback, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import JsPDF from 'jspdf';
import { toPng } from 'html-to-image';

// Utils
import bemify from 'utils/bemify';

// Components
import QrCard from 'components/qr-card';

// Styles
import stylesModule from './styles.module.scss';


const styles = bemify(stylesModule);

const QrCardDownloader = ({
  backgroundColor,
  description,
  displaySignature,
  downloadFormat,
  logoSrc,
  onError,
  onSuccess,
  restaurantSlug,
  restaurantName,
  textColor,
  title
}) => {
  const cardRef = useRef(null);
  const downloadWasExecuted = useRef(false);

  const handleQrCardDownload = useCallback(async () => {
    if (cardRef.current) {
      downloadWasExecuted.current = true;

      try {
        let cardUrl = null;

        if (downloadFormat === 'pdf') cardUrl = await toPng(cardRef.current);

        // ? This is commented because we don't have a place where we can select the download format, but is working
        // if (downloadFormat === 'svg') cardUrl = await toSvg(cardRef.current);

        if (downloadFormat === 'pdf') {
          // The numbers in the array correspond to the dimensions of the exported PDF file (requested by design)
          const pdf = new JsPDF({ format: [59, 89] });

          // Two extra pixels for the border
          await pdf.addImage(cardUrl, 'PNG', 0, 0, 59, 89);

          pdf.save(`${restaurantName ? `${restaurantName.split(' ').join('-')}-` : ''}qr-card.pdf`);
        }

        // ? This is commented because we don't have a place where we can select the download format, but is working
        // if (downloadFormat === 'svg') {
        //   const link = document.createElement('a');

        //   link.href = cardUrl;
        //   link.download = `${restaurantName ? `${restaurantName.split(' ').join('-')}-` : ''}qr-card.svg`;

        //   document.body.appendChild(link);

        //   link.click();

        //   document.body.removeChild(link);
        // }

        onSuccess();
      } catch (_) {
        onError();
      }
    }
  }, [downloadFormat, onError, onSuccess, restaurantName]);

  useLayoutEffect(() => {
    if (!downloadWasExecuted.current) {
      handleQrCardDownload();
    }
  }, [handleQrCardDownload]);

  return (
    <div className={styles.qrCardDownloader}>
      <QrCard
        backgroundColor={backgroundColor}
        cardRef={cardRef}
        description={description}
        displaySignature={displaySignature}
        logoSrc={logoSrc}
        restaurantSlug={restaurantSlug}
        size={downloadFormat === 'pdf' ? 'print' : 'm'}
        textColor={textColor}
        title={title}
      />
    </div>
  );
};

QrCardDownloader.propTypes = {
  backgroundColor: PropTypes.string,
  cardRef: PropTypes.shape({}),
  description: PropTypes.string,
  displaySignature: PropTypes.bool,
  downloadFormat: PropTypes.oneOf(['pdf']),
  logoSrc: PropTypes.string,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  restaurantName: PropTypes.string.isRequired,
  restaurantSlug: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  title: PropTypes.string
};

QrCardDownloader.defaultProps = {
  backgroundColor: null,
  cardRef: null,
  description: null,
  displaySignature: true,
  downloadFormat: 'pdf',
  logoSrc: null,
  onError: () => {},
  onSuccess: () => {},
  textColor: null,
  title: null
};

export default QrCardDownloader;
